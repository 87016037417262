import React from 'react'
import { Link } from '@material-ui/core'
import LinkedInIcon from '@material-ui/icons/LinkedIn'


/**
 *
 * @returns button with linkedin logo
 */
const LinkedinButton = () => (
      <Link
        href="https://www.linkedin.com/in/mark-hoekstra-bb0573aa"
        target="_blank"
        rel="noopener noreferrer"
        >
        <LinkedInIcon style={{ color: 'white' }} />
      </Link>
  )

export default LinkedinButton
