import React from 'react'

import classes from './page.module.css'

const page = (props) => {
    return (
        <div className={classes.background}
        ><div id={props.id}
            className={classes.container}>{props.children}</div></div>
    )
}

export default page

