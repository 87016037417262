import React from 'react'
import { makeStyles } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EmailIcon from '@material-ui/icons/Email'

const useStyles = makeStyles({
  image: {
    cursor: "pointer",
    color: 'white',
  },
})

const EmailButton = ({ showEmail }) => {
  const classes = useStyles()
  return (
      showEmail ?
        <ClearIcon className={classes.image} /> :
          <EmailIcon className={classes.image} />
  )
}

export default EmailButton
