import React from 'react'
import 'typeface-ubuntu'
import {Helmet} from "react-helmet"

import Banner from '../components/Banner'
import ButtonBar from '../components/ButtonBar'
import favicon16 from "../images/favicon16.png"
import favicon32 from "../images/favicon32.png"
import Page from '../utils/page'

export default () => (
  <div>
  <Helmet
    title="TulipTree.nl"
    meta={[
      {
        name: "description",
        content: "Mark Hoekstra - Software Developer",
      },
      {
        name: "keywords",
        content: "frontend, ,backend, python, javascript, react, keras, tenserflow, freelance, developer",
      },
    ]}
    link={[
      { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
      { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` }
    ]}
  />
    <Page id="home">
      <ButtonBar />
      <Banner />
    </Page>
  </div>
)