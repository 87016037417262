import React, {useState, useCallback} from "react"
import { Grid, Link } from '@material-ui/core'

import EmailButton from './buttons/EmailButton'
import LinkedinButton from './buttons/LinkedinButton'

const ButtonBar = () => {
  const [showEmail, setShowEmail] = useState(false)

  const handleClick = useCallback(() => {
    setShowEmail(!showEmail)
  }, [setShowEmail, showEmail])

  const email = 'mark@tuliptree.nl'

return (
      <Grid style={{ position: 'fixed', top:'1em', height:'10%'}}>
        <Grid container spacing={2} justify="center" >
          <Grid item onClick={handleClick}>
            <EmailButton showEmail={showEmail} />
          </Grid>
          <Grid item>
            <LinkedinButton />
          </Grid>
        </Grid>
        {showEmail &&
          <Grid item>
            <Link
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontFamily: "ubuntu", textDecoration: 'none', color: 'white'}}>{email}</Link>
          </Grid>}
      </Grid>
  )
}

export default ButtonBar
