import React from "react"
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  containerTextPrimary: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },
  textPrimary: {
    fontWeight: "600",
    fontSize: "4em",
    color: "white",
    fontFamily: "ubuntu, sans-serif",
    display: "flex"
  },
  textSecondary: {
    color: "white",
    fontFamily: "ubuntu, sans-serif",
    fontSize: "medium",
    display: "flex",
    width: "100%",
    height: "50px",
    justifyContent: "center",
    alignItems: "flex-end",
    fontWeight: "100"
  },
})

const Banner = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.containerTextPrimary}>
        <div className={classes.textPrimary}>
          Tulip
        </div>
        <div className={classes.textPrimary}>
          Tree
        </div>
        <div className={classes.textPrimary}>
          .nl
        </div>
      </div>

      <div className={classes.textSecondary}>
        Your partner in software development
      </div>
    </div>
  )
}

export default Banner